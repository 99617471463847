body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* font face */
@font-face {
  font-family: 'Klein-75Bold';
  src: url('../public/fonts/Klein-75Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Klein-65Medium';
  src: url('../public/fonts/Klein-65Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Klein-55Regular';
  src: url('../public/fonts/Klein-55Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Klein-45Light';
  src: url('../public/fonts/Klein-45Light.otf') format('opentype');
}

