



.container {
  width: 100svw;
  height: 100svh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.5); /* black */
}


.side_container {
  width: 37.7svh;
  height: 58svh;
  max-width: 30rem;
  max-height: 46.15rem;
  min-width: 20rem;
  min-height: 30.77rem;
  background-color: white;
  z-index: 10;
}

.absoluteControl{
  position: absolute;
  top: 1.5svh;
  right: 1.5svh;
  display: flex;
}


/* 256px 461.457px */
/* .block {
  position: absolute;
  top: 1.5svh;
  height: 1.5svh;      
} */

/* .header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
} */

/* .header a {
  text-align: center;
  padding: 5%;
} */

.slider_container {
  display: block;
}

.slider_container label {
  display: block;
  text-align: left;
}

.slider_wrapper {
  display: flex;
  align-items: center;
}

.slider {
  -webkit-appearance: none;
  flex: 1;
  cursor: pointer;
  background: black;
  border-radius: 1px; 
  height: clamp(0.3rem, 0.5svh, 0.8rem);
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  box-shadow: none;
  appearance: none;
  width: clamp(0.8rem, 1.5svh, 1.5rem);
  height: clamp(0.8rem, 1.5svh, 1.5rem);
  border-radius: 0;
  background: black;
  cursor: pointer;
}

.slider_value {
  /* width: 1svw; */
  text-align: center;
  border: 1px solid black;
  padding: 0 0.5rem;
}

.unit_btn_container {
  display: flex;
  justify-content: center;
}

.unit_btn {
  display: flex;
}

.unit_btn button {
  border: none;
  background-color: white;
  color: black;
  cursor: pointer;
}

.unit_btn button.selected {
  background-color: black;
  color: white;
}

.find_btn{
  background-color: black;
  color: white;
  align-self: center;
}

.close_icon_container{
  display: flex;
  justify-content: flex-end;
}

.close_icon{
  cursor: pointer;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  width: 100%;
}

.number_input{
  text-align: center;
  height: clamp(1.5rem, 2svh, 2rem);
}


/* .measurement-list li {
  position: relative;
  padding-left: 2rem;
  margin-top: 5%;
  line-height: 0.6rem;
} */

/* .measurement-list li::before {
  content: counter(li-counter);
  counter-increment: li-counter;
  position: absolute;
  left: 0;
  top: 0;
  width: 1svw;
  height: 1.5svh;
  background-color: black;
  color: white;
  text-align: center;
  line-height: 0.6rem;
  border-radius: 4px;
  margin-left: 0.5rem;
} */



/* qihua */

h1{
  font-size: clamp(1.2rem, 2.4svh, 3.2rem); 
  text-align: center;
  font-family: 'Klein-65Medium';
  font-weight: 500;
}

h2{
  font-family: 'Klein-65Medium';
  font-weight: 500;
}

.toBold{
  font-family: 'Klein-75Bold';
}

p{
  font-size: clamp(0.7rem, 1.3svh, 1.2rem);
  text-align: center;
  font-family: "Klein-55Regular";
  font-weight: 400;
  letter-spacing: 0.03rem;
}

#page2mobile p{
  font-size: 0.8rem !important;
}

#page2mobile .listStyle{
  font-size: 0.8rem !important;
  width: 1.6rem !important;
  height: 1.6rem !important;
  margin-right: 1rem;
}

h3 {
  font-size: clamp(1rem, 2svh, 1.5rem); 
  text-align: center;
  font-family: "Klein-55Regular";
  font-weight: 400;
}

h4{
  font-size: clamp(0.8rem, 1.6svh, 1.3rem); 
  font-family: 'Klein-55regular';
  text-align: center;
  font-weight: 400;
}

button{
  font-size: clamp(0.7rem, 1.5svh, 2rem);
  border: none;
  font-family: 'Klein-65Medium';
}
label{
  /* first charecter uppercase */
  font-size: clamp(0.65rem, 1.3svh, 1.2rem);
  text-transform: capitalize;
  font-weight: 400;
  font-family: 'Klein-55Regular';
}

input{
  font-size: clamp(0.65rem, 1.3svh, 1.8rem);
}

.block_mobile{
  display: none;
}

/* when screen is bigger then 500 */
@media screen and (min-width: 550px){
  #page2mobile{
    display: none;
  }
}

/* when screen is smaller then 500 */
@media (max-width: 550px) {
  .page2{
    display: none;
  }
  #page2mobile{
    display: block;
  }
  .container{
    touch-action: none;
  }
  .side_container {
    width: 100svw;
    height: 100svh;
    max-width: 100svw;
    max-height: 100svh;
    min-width: 100svw;
    min-height: 100svh;
  }
  .container .absoluteControl{
    position: relative !important;
    top: 0 !important;
    right: 0 !important;
  }

  h1{
    font-size: 1.8rem !important;
  }
  p{
    font-size: 1rem !important;
  }
  h3{
    font-size: 1.2rem !important;
  }
  h4{
    font-size: 1rem !important;
  }
  button{
    font-size: 1.2rem !important;
  }
  label{
    font-size: 0.9rem !important;
  }
  input{
    font-size: 1rem !important;
  }
  .find_btn{
    padding: 0.7rem 0 !important;
    width: 12rem !important;
  }

  .find_btn2{
    width: 8rem !important;
    height: 8rem !important;
  }

  .unit_btn_container button{
    padding: 0.35rem !important;
    padding-top: 0.55rem !important;
    width: 4rem !important;
  }

  .slider::-webkit-slider-thumb{
    /* remove all style */
    --webkit-appearance: none;
    width: 1.3rem !important;
    height: 1.3rem !important;
  }

  .slider{
    height: 0.35rem !important;
  }

  .block_mobile{
    display: block;
  }
}


span{
  text-transform: lowercase;
}

.measurement-list p{
  text-align: left;
  font-size: clamp(0.6rem, 1.2svh, 1rem) !important;
}
.measurement-list li{
  font-size: clamp(0.6rem, 1.2svh, 1rem) !important;
  margin-bottom: clamp(0.6rem, 0.7svh, 0.5rem);
  display: flex;
}
.measurement-list{
  width: fit-content;
  margin: 0 auto;
}

.blockLi{
  height: 0.8rem;
}

.listStyle{
  width: clamp(0.1rem, 1.9svh, 1.7rem);
  height: clamp(0.1rem, 1.9svh, 1.7rem);
  border-radius: 0.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: white;
  margin-right: 0.7rem;
  font-family: 'Klein-75Bold';
  font-weight: bolder;
  padding-top: 0.2rem;
}

.languagContainer{
  position: absolute;
  top: 2%;
  left: 2%;
  z-index: 1000;
  width: clamp(1.8rem, 2.2svh, 2.5rem);
  height: clamp(1.8rem, 2.2svh, 2.5rem);
}

.closeIcon{
  position: absolute;
  top: 2%;
  right: 2%;
  z-index: 1000;
  width: clamp(1.8rem, 2.2svh, 2.5rem);
  height: clamp(1.8rem, 2.2svh, 2.5rem);
}
.backIcon{
  z-index: 1000;
  width: clamp(2.2rem, 2.7svh, 2.7rem);
  height: clamp(2.2rem, 2.7svh, 2.7rem);
  top: 2%;
  transform: translateY(-5%);
  position: absolute;
}
.backIcon:hover{
  cursor: pointer;
  scale: 1.1;
  transition: 0.3s;
}
.closeIcon:hover{
  cursor: pointer;
  scale: 1.1;
  transition: 0.3s;
}
.closeIcon .leftIcon{
  position: absolute;
  height: 0.13rem;;
  width: clamp(1.8rem, 2.2svh, 2.5rem);
  background-color: black;
  transform: rotate(-45deg);
  top: 50%;
}

.closeIcon .rightIcon{
  position: absolute;
  height: 0.13rem;;
  width: clamp(1.8rem, 2.2svh, 2.5rem);
  background-color: black;
  transform: rotate(45deg);
  top: 50%; 
}


/* shake animation */
.shake{
  animation: shake 0.5s;
}
@keyframes shake {
  0% { transform: translateX(0); }
  10% { transform: translateX(-2px); }
  20% { transform: translateX(2px); }
  30% { transform: translateX(-2px); }
  40% { transform: translateX(2px); }
  50% { transform: translateX(-2px); }
  60% { transform: translateX(2px); }
  70% { transform: translateX(-2px); }
  80% { transform: translateX(2px); }
  90% { transform: translateX(-2px); }
  100% { transform: translateX(0); }
}